<template>
  <section>
    <header class="mb-2">
      <b-card>
        <b-row>
          <b-col md="4" sm="12">
            <p><strong>Nama Kendaraan</strong></p>
            <p>{{ ck.nama_kendaraan }}</p>
          </b-col>
          <b-col md="3" sm="12">
            <p><strong>Merk</strong></p>
            <p>{{ ck.merk_kendaraan }}</p>
          </b-col>
          <b-col md="2" sm="12">
            <p><strong>Jenis</strong></p>
            <p>{{ ck.jenis_kendaraan }}</p>
          </b-col>
          <b-col md="3" sm="12">
            <p><strong>Plat Nomer</strong></p>
            <p>{{ ck.plat_nomer ? ck.plat_nomer : "kosong" }}</p>
          </b-col>
        </b-row>
      </b-card>
    </header>

    <main>
      <b-card>
        <h5>Riwayat Pengecekan Kendaraan</h5>
        <b-row>
          <b-modal id="riwayat-modal" v-model="showModal">
            <b-form-group label="Tanggal">
              <flat-pickr
                v-model="form.tanggal"
                class="form-control"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
              />
            </b-form-group>
            <!-- <b-form-group label="Nominal">
              <b-form-input v-model="form.nominal" />
            </b-form-group> -->
            <b-form-group label="Pilih Driver">
              <b-form-select v-model="form.id_driver" :options="id_driver" />
            </b-form-group>
            <b-form-group label="Pilih Sales">
              <b-form-select v-model="form.id_gudang" :options="id_gudang" />
            </b-form-group>
            <b-form-group label="Keterangan">
              <b-form-textarea v-model="form.keterangan" />
            </b-form-group>
            <template #modal-footer>
              <div class="d-flex justify-content-end">
                <b-button variant="info" @click.prevent="submit">{{
                  isAdminGudang ? "Simpan" : "Simpan"
                }}</b-button>
              </div>
            </template>
          </b-modal>
          <!-- / -->
          <b-button variant="primary" @click.prevent="add">
            <feather-icon icon="PlusIcon" class="mr-50" />Pengecekan
          </b-button>
        </b-row>
        <b-row>
          <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" sm="8" class="my-1">
            <b-form-group
              label="Sort"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template v-slot:first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6" class="my-1">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table
              striped
              small
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(no)="row">
                <strong class="text-center">
                  {{ row.index + 1 }}
                </strong>
              </template>
              <template #cell(tanggal)="{item}">
                {{ humanDate(item.tanggal) }}
              </template>

              <template #cell(actions)="row">
                <b-button
                  v-b-tooltip.hover.right="'Cek'"
                  size="sm"
                  @click.prevent="$router.push(`/ceklist/rincian/${row.item.id}`)"
                  class="mr-1"
                  variant="primary"
                >
                  <feather-icon icon="ToolIcon" />
                </b-button>
              </template>
            </b-table>
          </b-col>
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </main>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BForm,
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BOverlay,
  BCardText,
  BCard,
} from "bootstrap-vue";

export default {
  components: {
    BForm,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BModal,
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BCardText,
    BCard,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showModal: false,
      loading: false,
      required,
      password,
      email,
      confirmed,
      form: {
        // id_gudang: "",
        tanggal: new Date(),
        kendaraan_id: 0,
        id_driver: null,
        id_gudang: null,
        status: 0,
        keterangan: null,
      },
      ck: {},
      id_gudang: [],
      id_driver: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 20,
      pageOptions: [20, 50, 60],
      totalRows: 1,
      currentPage: 1,
      sortBy: "tanggal",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "tanggal", label: "Tanggal", sortable: true },
        { key: "keterangan", label: "Keterangan" },
        { key: "driver.nama_lengkap", label: "Driver" },
        // { key: "kendaraan", label: "Kendaraan" },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      gk: {},
    };
  },
  watch: {
    "form.id_driver"(val) {
      this.form.id_gudang = val.id;
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    this.getKendaraan();
    this.getData();
    this.getDataGudangKendaraan();
  },
  methods: {
    async getKendaraan() {
      const ck = await this.$store.dispatch(
        "kendaraan/getDataById",
        this.$route.params.id
      );
      this.ck = ck;
    },
    async getDataGudangKendaraan() {
      await this.$store
        .dispatch("gudang/getData", {
          kendaraan_id: this.$route.params.id,
        })
        .then(() => {
          let gk = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          gk.map((item) => {
            item.value = item.driver.id;
            item.text = item.driver.nama_lengkap;
          });
          this.id_driver = gk;
          let gd = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          gd.map((item) => {
            item.value = item.id;
            item.text = item.karyawan.nama_lengkap;
          });
          this.id_gudang = gd;
          // this.form.id_driver = this.id_gudang.driver.id;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },

    // downloadAttachment(ajuan_id) {
    //   window.open(`/pengambilan-barang/download-attachment/${ajuan_id}`, "_blank");
    // },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    edit(item) {
      let { id, gudang, driver } = item;
      this.id = item.id;
      this.form.id = id;
      this.form.driver = driver;
      if (gudang) {
        this.form.id_gudang = gudang.id;
      }
      if (driver) {
        this.form.id_driver = driver.id;
      }
      this.showModal = true;
      this.$bvModal.show('riwayat-modal')
    },
    add() {
      this.resetForm();
      this.showModal = true;
      this.$bvModal.show('riwayat-modal')
      const userData = JSON.parse(localStorage.getItem("userData"));
      // if (userData.id) {
      //   // this.form.karyawan_id = userData.karyawan.id;
      //   this.form.id_gudang = this.gk.id;
      //   // this.form.id_driver = this.gk.driver.id;
      // }
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data ajuan barang ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(() => {
        item.fungsi = 1; // soft delete
        this.$store
          .dispatch("ceklist/save", [item])
          .then(() => {
            this.getData();
            this.displaySuccess({
              message: "Ajuan berhasil dihapus",
            });
            this.resetForm();
          })
          .catch((e) => {
            this.displayError(e);
            return false;
          });
      });
    },
    resetForm() {
      this.id = null;
      this.form.id = null;
      this.form.tanggal = new Date();
      this.form.keterangan = null;
    },
    async submit() {
      if (!this.form.tanggal) {
        this.displayError({
          message: "Harap lengkapi form dengan benar!",
        });
        return false;
      }

      let payload = this.form;
      if (this.id) [(payload.id = this.id)];

      this.loading = true;
      try {
        if (this.isAdminGudang) {
          payload.kendaraan_id = this.$route.params.id;
          // payload.id_driver = this.gk.driver.id;
          // payload.id_gudang = this.gk.id;
        }
        await this.$store.dispatch("ceklist/save", [payload]);
        this.loading = false;
        this.displaySuccess({
          message: "Tanggal Pengecekan Berhasil disimpan",
        });
        setTimeout(() => {
          this.showModal = false;
          this.resetForm();
          this.getData();
        }, 1000);
      } catch (e) {
        this.loading = false;
        this.displayError(e);
      }
    },
    async getData() {
      // set payload if sales itself, filter per sales itself
      let params = this.isAdminGudang
        ? { order: "desc", kendaraan_id: this.$route.params.id }
        : { order: "desc" };

      // if (this.selectedStatus && this.selectedStatus != "all") {
      //   params.status = this.selectedStatus;
      // }
      this.loading = true;
      const ajuans = await this.$store.dispatch("ceklist/getData", params);
      this.loading = false;
      this.items = ajuans;
      this.totalRows = ajuans.length;
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
  },
};
</script>
